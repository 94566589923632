<template>
  <static-fullscreen-card :loaded="loaded">
    <template v-slot:title> ({{ data.id }}) {{ data.city }} {{ data.address }} [{{ statusCurrent.text }}] </template>
    <template v-slot:actions>
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn color="primary" v-bind="attrs" v-on="on" :disabled="!loaded">
            <v-icon>mdi-debug-step-over</v-icon>
            <btn-title>Статус</btn-title>
          </v-btn>
        </template>
        <v-list>
          <v-list-item v-for="(item, index) in statusItems" :key="index" link>
            <v-list-item-title @click="clickStatus(item)">
              {{ item.action }}
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <a-btn-edit v-if="getAccess('lampObjects.edit', id)" @click="showEditDialog = true" :disabled="!loaded" />
    </template>
    <portal to="v-main">
      <edit-dialog v-model="showEditDialog" :id="id" :api="url" typeObject="lampObjects"></edit-dialog>
    </portal>
    <v-row>
      <v-col cols="12" sm="6">
        <material-card color="secondary">
          <template v-slot:heading>
            <div class="px-3 py-1">фото объекта</div>
          </template>
          <a-view-images :value="data.photos" :style="{ height: blockHeight + 'px' }" />
        </material-card>
      </v-col>
      <v-col cols="12" sm="6">
        <material-card color="secondary">
          <template v-slot:heading>
            <div class="px-3 py-1">основная информация</div>
          </template>

          <a-form-view
            :value="data"
            :model1="[model[0], model[3], model[4], model[5], { name: 'price_m2', title: 'Стоимость кв.м', type: 'string' }, model[7], model[8], model[11], model[12]]"
            :model="modelRight"
            :config="{ dense: true }"
            class="block_master"
          />
        </material-card>
      </v-col>
      <v-col cols="12">
        <material-card color="third">
          <template v-slot:heading>
            <v-tabs v-model="tab" background-color="transparent" slider-color="white">
              <v-tab v-for="(tab, i) in tabs" :key="i" class="mr-3">{{ tab.title }}</v-tab>
            </v-tabs>
          </template>
          <v-tabs-items v-model="tab" class="transparent" style="min-height: 200px" touchless>
            <v-tab-item>
              <v-card-text style="height: 300px; overflow: auto">
                <a-view-files :value="data.docs" />
              </v-card-text>
            </v-tab-item>
          </v-tabs-items>
        </material-card>
      </v-col>
    </v-row>
    <div v-if="!loaded" class="loading-block">
      <div class="loader"></div>
    </div>
    <confirm-dialog ref="confirmDialog"></confirm-dialog>
  </static-fullscreen-card>
</template>

<script>
import { statusChange, autoHeightBlock, getAccess, genModel, modelOptions } from "../../components/mixings";
export default {
  mixins: [statusChange, autoHeightBlock, getAccess, genModel, modelOptions],
  components: {
    editDialog: () => import("./../dialogs/objectsLampDialog"),
  },
  data() {
    return {
      id: 0,
      blockHeight: 250,
      showEditDialog: false,
      loaded: false,
      title: "",
      data: {},
      m: this.$store.getters["config/get"].models.objects,
      url: "/mechti/lamp_objects",
      tab: 0,
      tabs: [{ title: "Документация" }],
    };
  },
  created() {
    this.$root.title = this.config.title;
    this.id = Number(this.$route.params.id);
    this.fitchData();
  },
  computed: {
    statuses() {
      let res = [];

      //  res = this.getOptions({ options: "status_lamp_object,status_site" });
      res = this.getOptions({ options: "config.statuses.lamp_object,status_site" });
      return res;
    },
    statusCurrent() {
      let idx = this.statuses.findIndex((el) => {
        return el.value == this.data.status;
      });
      if (idx == -1) return {};
      return JSON.parse(JSON.stringify(this.statuses[idx]));
    },
    statusItems() {
      let items = [];
      let res = [];
      let status = {};
      let type;
      type = "status";
      status = this.getStatus(type);
      if (status?.["next"]) {
        res = [
          ...res,
          ...this.statuses.filter((s) => {
            return status.next.includes(s.value) && s.field == type;
          }),
        ];
      }
      type = "status_site";
      status = this.getStatus(type);
      if (status?.["next"]) {
        res = [
          ...res,
          ...this.statuses.filter((s) => {
            return status.next.includes(s.value) && s.field == type;
          }),
        ];
      }

      items = JSON.parse(JSON.stringify(res));

      return items;
    },
    modelRight() {
      let model = this.calcModel("config.lampObjects.viewForm2");
      return model;
    },
    config() {
      return this.m?.config?.lampObjects || {};
    },
    model() {
      //let config = JSON.parse(JSON.stringify(this.m?.config?.considers || {}));
      let model = this.calcModelConfig(this.config);
      return model;
    },
  },
  watch: {
    showEditDialog() {
      if (!this.showEditDialog) {
        this.fitchData();
      }
    },
  },
  methods: {
    async fitchData() {
      this.loaded = !true;
      const d = await this.$axios.get(this.url + "/" + this.id);
      if (d.data && d.data.data) {
        this.data = d.data.data;
        this.data.price_m2 = "";
        if (this.data.ap_area && this.data.ap_price_buy) {
          this.data.price_m2 = (this.data.ap_price_buy / this.data.ap_area).toFixed(2);
        }
      }
      this.loaded = true;
    },
  },
};
</script>
